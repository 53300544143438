// .storybook/YourTheme.js

import { create } from '@storybook/theming';
import logo from './CompaLogo.svg';

export default create({
    base: 'light',
    brandTitle: 'Compa UI Docs',
    brandUrl: 'https://compa-ui.com',
    brandImage: logo,
    brandTarget: '_self',
});